<template>
  <div class="all-container">
    <div class="top-banner">
      <el-image :src="topBanner" width="100%" lazy></el-image>
    </div>
    <div class="container-mb">
      <div class="list-top">
        <div class="list-top-title">{{ $t('热门餐厅') }}</div>
      </div>
      <div v-if="dataList && dataList.length > 0" v-loading="dataListLoading" style="margin-top: 30px">
        <listPane ref="listPane" />
      </div>
      <div v-else v-loading="dataListLoading">
        <div class="list-not">
          {{ $t('列表暂无数据') }}
        </div>
      </div>
      <!-- <div class="list-page">
        <el-pagination :current-page="searchForm.page" :page-size="searchForm.pageSize" :total="sizeAll" background layout="prev, pager, next" @current-change="currentChangeHandle" />
      </div> -->
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import { storeRecommendList } from '@/api/store'
import { formatMoney } from '@/utils/money'
import { weeks, storeService, storeShopType } from '@/utils/public_data'
import listPane from '@/views_mb/list/index'

export default {
  components: { listPane },
  filters: {
    moneyFilter(val) {
      return formatMoney(val)
    },
    timeFilter(val) {
      return val.substring(0,5)
    }
  },
  data() {
    return {
      searchForm: {
        keywords: '',
        page: 1,
        pageSize: 6
      },
      sizeAll: 0,
      dataList: [],
      service: storeService(),
      weeks: weeks(),
      areaList: {},
      moneyList: {},
      imglist1: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa0.att.hudong.com%2F30%2F29%2F01300000201438121627296084016.jpg&refer=http%3A%2F%2Fa0.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1614823486&t=a246fc320a07d6426b9d99dac10e8553',
      imglist2: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa0.att.hudong.com%2F30%2F29%2F01300000201438121627296084016.jpg&refer=http%3A%2F%2Fa0.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1614823486&t=a246fc320a07d6426b9d99dac10e8553',
      shopLogo: require('@/assets/images/shop.png'),
      forward: require('@/assets/images/forward.png'),
      notImg: require('@/assets/images/not-image.png'),
      topBanner: require('@/assets/images/banner/phone/banner-1.png'),
      shopType: storeShopType(),
      thumbsSwiper: {},
      swiper: {},
      dataForm: {},
      dataListLoading: false
    }
  },
  computed: {
    lan() {
      return localStorage.getItem('locale')
    }
  },
  watch: {
    lan(newVal) {
      if (newVal) {
        console.log(newVal)
        this.searchHandle()
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    handle(id) {
      this.$router.push({ path: '/detail', query: { id }})
    },
    initSwiper(index) {
      this.thumbsSwiper[index] = new Swiper(`.img-thumbs${index}`, {
        spaceBetween: 10,
        slidesPerView: 3,
        nextButton: `.swiper-button-next_${index}`,
        prevButton: `.swiper-button-prev_${index}`,
        loop: false,
        watchSlidesVisibility: true,/*避免出现bug*/
      })
      this.swiper[index] = new Swiper(`.img-top${index}`, {
        spaceBetween: 10,
        nextButton: `.swiper-button-next_${index}`,
        prevButton: `.swiper-button-prev_${index}`,
        speed: 1000,
        loop: false,
        controller:{
          control: this.thumbsSwiper[index],
        },
        thumbs: {
          swiper: this.thumbsSwiper[index],
        }
      })
    },
    listHandle() {
      this.$router.push('/recommend')
    },
    init() {
      this.getDataList()
    },
    handlerBMap({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      // console.log('22222', BMap.Point(this.detailData.longitude, this.detailData.latitude))
      this.$set(this.center, 'lat', this.detailData.latitude)
      this.$set(this.center, 'lng', this.detailData.longitude)
      console.log(this.center)
    },
    searchHandle() {
      this.searchForm.page = 1
      this.getDataList()
    },
    getDataList() {
      // 获取数据
      this.dataListLoading = true
      let activity = 3
      storeRecommendList({ activity }).then(response => {
        if(response && response.data) {
          this.dataList = response.data
          this.$nextTick(() => {
            if (this.dataList && this.dataList.length > 0) {
              this.$refs.listPane.init(this.dataList)
            }
          })
          this.dataListLoading = false
        } else {
          this.dataList = []
          this.sizeAll = 0
          this.dataListLoading = false
        }
      }).catch(() => {
        this.dataList = []
        this.sizeAll = 0
        this.dataListLoading = false
      })
    },
    // 当前页
    currentChangeHandle(val) {
      this.searchForm.page = val
      this.getDataList()
    },
    // 进入店铺
    detailHandle(id) {
      this.$router.push({ path: '/detail', query: { id: id }})
    }
  }
}
</script>
<style lang='scss' scoped>
.test {
  width: 100%;
  min-height: 900px;
}
.top-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  background: #000;
  // background: url('../../assets/images/banner_hd.png')  no-repeat;
  .banner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 100;
    .banner-title {
      text-align: center;
      color: #fff;
      font-size: 30px;
    }
  }
}
.not-image {
  width: 120px;
  width: 120px;
  background: rgb(244,244,244);
}
.list-top {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .list-top-title {
    height: 20px;
    // line-height: 20px;
    padding-left: 20px;
    border-left: 4px solid #E7B530;
    font-size: 18px;
    font-weight: 600;
  }
  .list-top-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .list-key-inp {
      margin-left: 20px;
      width: 200px;
    }
    .list-key-btn {
      margin-left: -2px;
      background: #E7B530;
      color: #000;
      font-weight: 600;
      zoom: -10;
    }
  }
}
.special-box {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
  height: 180px;
  background: url('../../assets/images/special.jpg') no-repeat center center;
  z-index: 1;
  border-radius: 3px;
  .content-box {
    padding: 10px;
    width: 80%;
    background: rgba(231,181,48, 0.7);
    border-radius: 2px;
    box-sizing: border-box;
    .content-inner-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border: 2px dashed #fff;
      border-radius: 6px;
      .special-title1 {
        margin-top: 10px;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        color: #fff;
      }
      .special-title2 {
        margin-top: 10px;
        font-size: 18px;
        color: #fff;
      }
      .enter-btn {
        padding: 7px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #333;
        &:hover {
          background: #fff;
          color: #E7B530;
          // border: 4px solid #E7B530;
        }
      }
    }
  }
}
.list {
  padding-top: 16px;
  border-top: 1px solid #DCDFE6;
  width: 100%;
  min-height: 140px;
  &:last-child {
    border-bottom: 1px solid #DCDFE6;
  }
  .list-dis-box {
    width: 100%;
    height: 30px;
    line-height: 30px;
    background: #FDF6EB;
    .cost-discount {
      margin: 16px 0 ;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: 12px;
      .cost-title {
        margin-top: 0px;
        font-size: 12px;
        .const-red-content {
          color: #F56C6C;
        }
      }
    }
  }
  .list-box {
    display: flex;
    flex-wrap: nowrap;
    .list-image-box {
      width: 120px;
      .list-image {
        width: 120px;
      }
    }
    .list-content {
      // padding: 0 6px;
      //
      flex: 1;
      // max-width: 240px;
      .list-cost {
        margin-top: 20px;
        display: flex;
        font-weight: 600;
        font-size: 12px;
        .cost-text {
          color:  #F56C6C;
        }
      }
      .list-info-item {
        margin-top: 8px;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 12px;
      }
      .list-rate {
        margin: 8px;
      }
      .item-title {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        .item-title-content {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          .list-title-content {
            width: 100%;
            margin-left: 0px;
            ::v-deep .el-link {
              display: block;
              width: 240px;
              font-size: 16px;
              font-weight: 600;
              color: #000;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              &:hover {
                color: #E7B530;
              }
            }
            ::v-deep .el-link--inner {
              display: block;
              width: 100%;
              font-size: 16px;
              font-weight: 600;
              color: #000;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              &:hover {
                color: #E7B530;
              }
            }
            margin-left: 10px;

          }
        }
        .shop-log {
          width: 28px;
        }
        .forward-logo {
          width: 28px;
        }
      }
    }
  }
}
.list-page {
  margin-top: 20px;
  text-align: center;
  .el-pagination {
    ::v-deep .btn-prev {
      background-color: #fff;
      border: 1px solid #DCDFE6;
    }
    ::v-deep .btn-next {
      background-color: #fff;
      border: 1px solid #DCDFE6;
    }
    ::v-deep .el-pager {
      .number {
        background-color: #fff;
        color: #909399;
        border: 1px solid #DCDFE6;
      }
      li:not(.disabled).active {
        background-color: #E7B530;
      }
    }
  }
}
.list-not {
  width: 100%;
  min-height: 160px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
